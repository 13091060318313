<template>
    <div :data-id="item.id" class="slider-card-container">
        <div :data-id="item.id" class="slider-card">
            <div class="first-subtitle ws-text-white ws-bg-black ws-font-bold ws-px-2 ws-py-1 ws-uppercase ws-text-sm">
                {{ item.year }} {{ item.make }} {{ item.model }}
            </div>
            <!-- IMAGE -->
            <a :href="carLink" :target="carLinkTarget" rel="noopener noreferrer" @click="trackClick(item)">
                <car-images :url="urlImage" :title="item.title" :sold="item.sold" :sale_pending="item.sale_pending" />
            </a>
            <!-- VIN AND STOCK -->
            <div
                class="vin-stock ws-border-gray-100 ws-border-b ws-text-xs ws-uppercase ws-flex"
                v-if="item.vin || item.stock"
            >
                <span class="ws-p-2 ws-pr-0 ws-mr-2" v-if="item.stock && item.stock.length < 32"
                    ><span class="data-content">STOCK: {{ item.stock }}</span></span
                >
                <span class="ws-p-2 ws-pr-0" v-if="item.vin"
                    ><span class="data-content">VIN: {{ item.vin }}</span>
                </span>
            </div>
            <!-- TITLE -->
            <h2 class="flex title ws-p-2 ws-border-b ws-border-gray-100">
                <a
                    class="ws-text-black ws-no-underline hover:ws-text-grey-darker ws-text-base ws-overflow-hidden"
                    :href="carLink"
                    :target="carLinkTarget"
                    rel="noopener noreferrer"
                    @click="trackClick(item)"
                >
                    {{ item.title }}
                </a>
            </h2>
            <!-- PRICE AND KM -->
            <div class="ws-flex ws-flex-wrap">
                <div class="flex ws-w-full ws-flex-row ws-p-2">
                    <template v-if="item.price && item.price != '' && item.price != 0">
                        <div class="box-title ws-w-16 ws-pr-1 ws-flex ws-items-center">
                            {{ priceTitle }}
                        </div>
                        <div class="box-content prices">
                            {{ item.price | price }}
                        </div>
                    </template>
                    <a
                        class="w-full pr-1 no-underline box-title"
                        :href="carLink"
                        :target="carLinkTarget"
                        rel="noopener noreferrer"
                        @click="trackClick(item)"
                        v-else
                    >
                        Click for Price
                    </a>
                </div>
                <div
                    class="extra-info ws-flex ws-w-full ws-p-2"
                    v-if="
                        (item.msrp_price && item.msrp_price != item.price) ||
                        item.discount_price ||
                        (item.miles && distance_measure == 'mile') ||
                        (item.kilometer && distance_measure == 'kilometer')
                    "
                >
                    <span class="ws-mr-4" v-if="item.msrp_price && item.msrp_price != item.price"
                        >MSRP: {{ item.msrp_price | price }}</span
                    >
                    <span class="ws-mr-4 discount" v-if="item.discount_price"
                        >Save: {{ item.discount_price | price }}</span
                    >
                    <span class="ws-mr-4" v-if="item.miles && distance_measure == 'mile'"
                        >Mileage: {{ item.miles }} mi.</span
                    >
                    <span class="ws-mr-4" v-if="item.kilometer && distance_measure == 'kilometer'"
                        >Mileage: {{ item.kilometer }} km.</span
                    >
                </div>
                <div class="extra-info ws-flex ws-w-full ws-p-2" v-else>
                    <span class="ws-mr-4">Click for more information</span>
                </div>
            </div>
            <!-- DEALER -->
            <a
                :href="carLink"
                :target="carLinkTarget"
                class="dealer-info ws-flex ws-cursor-pointer ws-border-gray-100 ws-border-b"
            >
                <div
                    class="ws-w-1/2 ws-h-12 ws-overflow-hidden ws-bg-no-repeat ws-bg-contain ws-bg-center ws-bg-white"
                    :style="'background-image: url(' + showDealer + ')'"
                ></div>
                <div
                    class="dealer-phone ws-text-sm ws-w-1/2 ws-bg-gray-100 ws-flex ws-justify-center ws-flex-col ws-p-2 ws-h-12 ws-overflow-hidden"
                >
                    <p class="ws-text-black ws-text-center">Start Saving!</p>
                </div>
            </a>
        </div>
    </div>
</template>

<script>
import { price } from '../../../filters/filters';
import { gotodealerpage } from '../../modules/dealers';
import carImages from '../carImages.vue';
import { apiBaseUrl } from '../../../config';
var sha1 = require('sha1');

export default {
    created() {
        this.currentGroup = this.groupsConfig.find(o => {
            return (o.id = this.item.group.id);
        });
    },
    computed: {
        priceTitle() {
            return this.currentGroup.fields.titleForPrice || 'Dealer Price';
        },
        carLink() {
            if (this.item.by_owner === 1) {
                this.carLinkTarget = '_self';
                return '/dealer/fsbo-for-sale-by-owner';
            }
            if (this.item.user_id != null) {
                this.carLinkTarget = '_self';
                return '/cars/vdp/' + this.item.id;
            }
            let carLink = this.item.url + '?utm_source=' + this.utm_source;
            let tracking_domain =
                this.tracking_domain != '' && this.tracking_domain != undefined
                    ? this.tracking_domain
                    : 'https://todaysdrive.aws.wehaa.net';
            return (
                tracking_domain +
                '/go.php?web=1&dealer_id=' +
                this.item.dealer.id +
                '&article_id=' +
                this.item.id +
                '&ga_id=' +
                this.ga_tracking +
                '&source=slider&url=' +
                encodeURIComponent(carLink) +
                '&vh=' +
                sha1(carLink + this.item.id + '$aZ!xGZH21')
            );
        },
        showDealer() {
            if (this.item.dealer.logo && this.item.dealer.logo != '') {
                return this.item.dealer.logo;
            }
            return false;
        },
        urlImage() {
            if (this.item.image && this.item.image != '') {
                return this.item.image;
            }

            if (this.car_default_placeholder != null) {
                return this.car_default_placeholder;
            }

            return apiBaseUrl + '/images/car.default.png';
        }
    },
    components: {
        'car-images': carImages
    },
    props: {
        groupsConfig: {
            required: true,
            type: Array
        },
        item: {
            required: true,
            type: Object
        },
        utm_source: {
            required: false,
            type: String
        },
        tracking_domain: {
            required: false,
            type: String
        },
        ga_tracking: {
            required: false,
            type: String
        },
        tracking: {
            required: true
        },
        distance_measure: {
            required: false,
            default: 'mile'
        },
        car_default_placeholder: {
            required: true,
            default: '/images/car.default.png'
        }
    },
    data() {
        return {
            carLinkTarget: '_blank',
            currentGroup: {}
        };
    },
    filters: {
        price
    },
    methods: {
        sha1,
        gotodealerpage,
        trackClick(item) {
            item.context = 'slider';
            item.context_id = 'slider_id';
            this.tracking.sendevent('click', {
                event_category: 'car',
                event_label: JSON.stringify(item),
                dealer_id: item.dealer.id,
                car_id: item.id,
                context: 'slider',
                context_id: 'slider_id'
            });
        }
    }
};
</script>
